const jQ = jQuery.noConflict();

class LoadButtonsSection {
	constructor(form = '.form-download', buttonsWrapper='.buttons-wrapper') {
		this.form = form;
		this.buttonsWrapper = buttonsWrapper;
	}

	showButtons(e) {
		e.preventDefault();

		const formInput = jQ(e.currentTarget).find('input');
		const emailInput = jQ(e.currentTarget).find('input[name="email-field"]');
		const emailInputVal = emailInput.val();
		const emailRegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		let errors = 0;

		formInput.each( (index, element) => {
			const formInputVal = jQ(element).val();
			const inputName = (jQ(element).attr('name')).replace('-', ' ');
			const errorField = jQ(element).closest('.form-group').find('.error-message');

			errorField.html('');

			if( formInputVal.length === 0 ) {
				const message = `Your ${inputName} must contain at least one character`;
				errorField.text(message);
				errors++;
			}
			else {
				if( jQ(element).attr('type') === 'email' ) {
					if( !emailRegExp.test(emailInputVal) ) {
						const message = `Your ${inputName} value is invalid`;
						errorField.text(message);
						errors++;
					}					
				}

				if( jQ(element).attr('type') === 'checkbox' ) {
					const message = `Please check the ${inputName} field`;
					if( !jQ(element).is(':checked') ) {
						errorField.text(message);
						errors++;
					}
				}
			}
		} );

		if( errors === 0 ) {
			jQ(e.currentTarget).slideUp(400);
			jQ(this.buttonsWrapper).stop().fadeTo(1500, 1);
		}
	}

	init() {
		jQ(this.form).on('submit', (e) => this.showButtons(e))
	}

}

const loadButtons = new LoadButtonsSection('.download-form', '.buttons-wrapper');

export default loadButtons;