const jQ = jQuery.noConflict();

class SetColHeight {
	constructor(baseColumn='.img-left', setColumn='.content-right') {
		this.baseColumn = baseColumn;
		this.setColumn = setColumn;
	}

	init() {
		this.setHeight();
	}

	setHeight() {
		const baseColumnHeight = jQ(this.baseColumn).outerHeight();
		jQ(this.setColumn).css("height", baseColumnHeight);
	}
}

const setColumnHeight = new SetColHeight('.img-left', '.content-right');

export default setColumnHeight;
