const jQ = jQuery.noConflict();

class OpenCloseSubMenu {
	constructor(selector='.sub-title') {
		this.selector = selector;
	}

	init() {
		jQ(this.selector).on('click', (e) => this.toggleTheSubMenu(e));
	}

	toggleTheSubMenu(e) {
		const subMenu = jQ(e.currentTarget).parent().find('.sub-menu');
		if (!jQ(subMenu).hasClass('is-active')) 
		{
			jQ(subMenu).addClass('is-active');
			jQ(subMenu).slideDown();
		}
		else 
		{
			jQ(subMenu).slideUp(function() {
				jQ(subMenu).removeClass('is-active');
			});
		}
	}
}

const toggleSubMenu = new OpenCloseSubMenu('.sub-title');

export default toggleSubMenu;
