const jQ = jQuery.noConflict();

class SwitchTheSlides {
	constructor(selector='.controlls-wrapper .controlls-row-regular', slider='.controls-slider') {
		this.selector = selector;
		this.slider = slider;
	}

	init() {

		this.setActiveSlideControl();
		jQ(this.selector).on('click', (e) => this.switchSlide(e));
	}

	switchSlide(e) {
		const slideIndex = jQ(e.currentTarget).data("slide");

		jQ(this.selector).each( (index, element) => {
			if(jQ(element).hasClass("is-active")) {
				jQ(element).removeClass("is-active")
			}
		});
		jQ(e.currentTarget).addClass('is-active');
		jQ(this.slider).slick('slickGoTo', slideIndex);
	}

	setActiveSlideControl() {
		jQ(this.selector).first().addClass("is-active");
	}
}

const switchSlides = new SwitchTheSlides('.controlls-wrapper .controlls-row-regular', '.controls-slider');

export default switchSlides;
