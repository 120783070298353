const jQ = jQuery.noConflict();

class Accordion {
	constructor(selector='.accordion-item-name', accordionList='.accordion-list', accordionItem='.accordion-item') {
		this.selector = selector;
		this.accordionList = accordionList;
		this.accordionItem = accordionItem;
	}

	init() {
		jQ(this.selector).on('click', (e) => this.openCloseAccordion(e));
		
	}

	openCloseAccordion(e) {
		const activeAccordionList = jQ(e.currentTarget).parent().find(this.accordionList);
		const activeAccordionItem = jQ(e.currentTarget).parent();
		const that = this;

		jQ(this.accordionItem).each( (index, element) => {
			if(jQ(element).hasClass('is-open')){
				jQ(element).find(that.accordionList).slideUp( () => {
					jQ(element).removeClass('is-open');
				});
			}
		});

		if(!jQ(activeAccordionItem).hasClass('is-open')) {
			jQ(activeAccordionItem).addClass('is-open');
			jQ(activeAccordionList).slideDown();		
		}
		else {
			jQ(activeAccordionList).slideUp( () => {
				jQ(activeAccordionItem).removeClass('is-open');
			});
		}
	}
}

const toggleAccordion = new Accordion('.accordion-item-name', '.accordion-list', '.accordion-item');
const toggleSliderAccordion = new Accordion('.toggle-accordion', '.accordion-content-hidden', '.accordion-wrapper');

export default toggleAccordion;
export { toggleSliderAccordion }