import setupSlider from '../utils/setupslider.js';
import { setupTestimonialSlider, setupSliderWithAccordion, setupSliderWithControlls, setupSliderWithSideinfo, setupImageSlider, setupImageNarrowSlider, setupImageWideSlider } from '../utils/setupslider.js';
import toggleMenu from '../utils/togglemenu.js';
import toggleSubMenu from '../utils/togglesubmenu.js';
import scrollUp from '../utils/scrollup.js';
import equalColumns from '../utils/equalcolumns.js';
import { equalColumnsInner, equalColumnsUnits } from '../utils/equalcolumns.js';
import animateHeaderUp from '../utils/animateheaderup.js';
import toggleAccordion from '../utils/accordion.js';
import { toggleSliderAccordion } from '../utils/accordion.js';
import openVideo from '../utils/openvideo.js';
import { openVideoFull } from '../utils/openvideo.js';
import setMenuItem from '../utils/setmenuitem.js';
import setPageMargin from '../utils/setpagemargin.js';
import setColumnHeight from '../utils/setcolumnheight.js';
import positionLineConnect from '../utils/positionlineconnect.js';
import setHeaderSticky from '../utils/setheadersticky.js';
import switchSlides from '../utils/switchslides.js';
import loadButtons from '../utils/loadbuttons.js';

class Controller {

    init() {
        document.querySelector('html').classList.remove('no-js');
        setupSlider.init();
        setupTestimonialSlider.init();
        setupSliderWithAccordion.init();
        setupSliderWithControlls.init();
        setupSliderWithSideinfo.init();
        setupImageSlider.init();
        setupImageNarrowSlider.init();
        setupImageWideSlider.init();
        toggleMenu.init();
        toggleSubMenu.init();
        scrollUp.init();
        toggleAccordion.init();
        toggleSliderAccordion.init();
        setMenuItem.init();
        switchSlides.init();
        loadButtons.init();

    }
    loaded() {
        document.querySelector('body').classList.add('page-has-loaded');
        equalColumns.init();
        equalColumnsInner.init();
        equalColumnsUnits.init();
        animateHeaderUp.init();
        openVideo.init();
        openVideoFull.init();
        setPageMargin.init();
        positionLineConnect.init();
        setHeaderSticky.init();
    }
    resized() {
        animateHeaderUp.init();
        openVideo.adjustVideoSectionOnResize();
        openVideoFull.adjustVideoSectionOnResize();
        setPageMargin.init();
        positionLineConnect.init();
        setHeaderSticky.init();
    }
    scrolled() {
        setHeaderSticky.init();
    }
}

const con = new Controller();

export default con;