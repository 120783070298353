const jQ = jQuery.noConflict();

class ScrollHeader {
	constructor(selector='.scroll-down', slider='.banner-slider') {
		this.selector = selector;
		this.slider = slider;
	}

	scrollUp() {
		const sliderHeight = jQ(this.slider).outerHeight();
		if(!jQ('html, body').is(':animated')) {
			jQ('html, body').animate({
				scrollTop: sliderHeight
			}, 500);			
		}
	}

	init() {
		jQ(this.selector).on('click', (e) => this.scrollUp());
	}
}

const animateHeaderUp = new ScrollHeader('.scroll-down', '.banner-slider');

export default animateHeaderUp;