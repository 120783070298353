const jQ = jQuery.noConflict();

class SetMargin {
	constructor(bannerSlider='.banner-slider', main='main') {
		this.bannerSlider = bannerSlider;
		this.main = main;
	}

	setMarginTop() {	
		const bannerHiddenHeight = jQ('.banner-hidden').outerHeight();
		jQ(this.main).css({
			"margin-top": bannerHiddenHeight
		});
	}

	init() {
		jQ(this.bannerSlider).on('setPosition', this.setMarginTop());
	}

}

const setPageMargin = new SetMargin('.banner-slider', 'main');

export default setPageMargin;