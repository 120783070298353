const jQ = jQuery.noConflict();

class Slider {
	constructor( selector='.banner-slider-wrapper', slideNo='1', arrows='true', dots='true', setArrowCol='true', autoplay='true'){
		this.selector = selector;
		this.slideNo = slideNo;
		this.arrows = arrows;
		this.dots = dots;
		this.setArrowCol = setArrowCol;
		this.autoplay= autoplay;
	}

	init() {
		this.setSlider();
	}

	setSlider() {
		if (jQ(this.selector).length) {
	        jQ(this.selector).slick ({
	        	slidesToShow: this.slideNo,
	        	arrows: this.arrows,
	        	dots: this.dots,
	        	swipe: true,
	        	infinite: true,
	        	autoplay: this.autoplay
	        });
	    }

	    if(this.setArrowCol) {
	    	jQ(this.selector).on('beforeChange', (e) => this.setArrowColors(e));
	    }
	    else {
	    	jQ('.slick-arrow, .scroll-down').addClass('black-arrow');
		}
		
		if(this.selector === '.controls-slider') {
			jQ(this.selector).on('afterChange', (slick, slide) => {
				const table = slide.currentSlide;
				jQ('.controlls-row.is-active').removeClass('is-active');
				jQ(`.controlls-row[data-slide="${table}"]`).addClass('is-active');
			});
		}
	}

	setArrowColors() {
		if(!jQ('.slick-arrow').hasClass('black-arrow')) {
			jQ('.slick-arrow, .scroll-down').addClass('black-arrow');
		}
		else{
			jQ('.slick-arrow, .scroll-down').removeClass('black-arrow');
		}
	}
}

const setupSlider = new Slider('.banner-slider-wrapper', 1, true, true, true, true);
const setupTestimonialSlider = new Slider('.testimonial-slider', 1, true, true, false, false);
const setupSliderWithAccordion = new Slider('.slider-with-accordion', 1, true, true, false, false);
const setupSliderWithControlls = new Slider('.controls-slider', 1, true, false, false, false);
const setupSliderWithSideinfo = new Slider('.sideinfo-slider', 1, true, false, false, false);
const setupImageSlider = new Slider('.image-slider-wrapper', 1, true, true, false, false);
const setupImageNarrowSlider = new Slider('.image-text-slider-narrow .image-text-slides', 1, true, false, false, false);
const setupImageWideSlider = new Slider('.image-text-slider-wide .image-text-slides', 1, true, false, false, false);

export default setupSlider;

export {
	setupTestimonialSlider,
	setupSliderWithAccordion,
	setupSliderWithControlls,
	setupSliderWithSideinfo,
	setupImageSlider,
	setupImageNarrowSlider,
	setupImageWideSlider,
};
