import con from './init/controller';

con.init();

window.onload = () => {
	con.loaded();
};
window.onresize = () => {
	con.resized();
};
jQuery(window).on('scroll', () => {
	con.scrolled();
});

jQuery(window).bind('mousewheel', () => {
	jQuery('html body').stop(true, false)
});
