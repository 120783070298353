const jQ = jQuery.noConflict();

class EqualCol {
	constructor(column='.grid-content-single', columnWrapper='.grid-content', columnNo='2', columnNoLg='1', columnNoMd='1') {
		this.column = column;	
		this.columnWrapper = columnWrapper;
		this.columnNo = columnNo;
		this.columnNoLg = columnNoLg;
		this.columnNoMd = columnNoMd;
	}

	init() {
		this.setEqualColumns();
	}

	setEqualColumns() {
		if (jQ(this.columnWrapper).length) {
	        jQ(this.column).evenColumns (
	            {
	                columns: this.columnNo
	            },
				{
	                maxScreenWidth: 991,
	                columns: this.columnNoLg
	            },
	            {
	                maxScreenWidth: 768,
	                columns: this.columnNoMd
	            },
	        )
	    }
	}
}

const equalColumns = new EqualCol('.grid-content-single', '.grid-content', 2, 1, 1);
const equalColumnsInner = new EqualCol('.single-image', '.grid-content', 2, 1, 1);
const equalColumnsUnits = new EqualCol('.single-unit', '.column-wrapper', 2, 1, 1);

export default equalColumns;

export { 
	equalColumnsInner, 
	equalColumnsUnits 
}