const jQ = jQuery.noConflict();

class OpenCloseMenu {

	constructor(selector='.menu-open', seletorAlt='.menu-close', menuContainer='.menu-container', subMenu='.sub-menu') {
		this.selector = selector;
		this.selectorAlt = seletorAlt;
		this.menuContainer =  menuContainer;
		this.subMenu = subMenu;
	}

	init() {
		jQ(this.selector).on('click', (e) => this.openMenu(e));
		jQ(this.selectorAlt).on('click', (e) => this.closeMenu(e));
	}

	openMenu(e) {
		if(!jQ(this.menuContainer).hasClass('is-visible')) {
			jQ(this.menuContainer).addClass('is-visible');
			jQ("body").addClass("no-scroll");	
		}
	}

	closeMenu(e) {
		if(jQ(this.menuContainer).hasClass('is-visible')) {
			jQ(this.menuContainer).removeClass('is-visible');
			jQ("body").removeClass("no-scroll");	
		}

		jQ(this.subMenu).each( (index, element) => {

			// hide the submenu for the next time menu gets loaded
			if(jQ(element).hasClass('is-active')) {
				jQ(element).slideUp( () => {
					jQ(element).removeClass('is-active');
				});
			}

		});
	}
}

const toggleMenu = new OpenCloseMenu('.menu-open', '.menu-close', '.menu-container', '.sub-menu');

export default toggleMenu;