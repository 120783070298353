const jQ = jQuery.noConflict();

class ScrollFromBottom {
	constructor(selector='.scroll-up') {
		this.selector = selector;
	}

	init() {
		jQ(this.selector).on('click', (e) => this.animateScroll());
	}

	animateScroll() {
		jQ('html, body').animate({
			scrollTop: 0
		}, 500);
	}
}

const scrollUp = new ScrollFromBottom('.scroll-up');

export default scrollUp;