const jQ = jQuery.noConflict();

class PositionElement {
	constructor(lineWrapper='.line-wrapper', lineElement='.line-connection', singleUnit='.stop', stopCircle='.stop-circle', bottomWrapper='.bottom-wrapper', topWrapperImg='.top-wrapper svg', activeCircle='.stop-circle-active') {
		this.lineWrapper = lineWrapper;
		this.lineElement = lineElement;
		this.singleUnit = singleUnit;
		this.stopCircle = stopCircle;
		this.bottomWrapper = bottomWrapper;
		this.topWrapperImg = topWrapperImg;
		this.activeCircle = activeCircle;
	}

	init() {
		this.setPosition();
	}

	setPosition() {

		if(jQ(this.lineWrapper).length) {
			const firstDotPositionX = jQ(this.singleUnit).outerWidth() / 2;
			const firstDotPositionY = jQ(this.singleUnit).outerHeight() / 2;
			const lineElemWidth = jQ(this.lineWrapper).outerWidth() - 2 * firstDotPositionX;
			const lineElemHeight = jQ(this.lineWrapper).outerHeight() - 2 * firstDotPositionY;
			const stopCircleHeight = jQ(this.stopCircle).outerHeight();
			const lineElemPosTop = jQ(this.singleUnit).position().top + stopCircleHeight/2;
			const lineElemPosTopAlt = jQ(this.stopCircle).position().top + stopCircleHeight/2;
			const singleUnitWidth = jQ(this.singleUnit).outerWidth();
			const singleUnitWidthHalf = singleUnitWidth / 2;
			const singleUnitHeight = jQ(this.singleUnit).outerHeight();
			const singleUnitHeightHalf = singleUnitHeight / 2;
			const topWrapperImgDistanceX = jQ('.stop-circle-active').position().left + singleUnitWidthHalf;
			const positionLeftLineConnection = jQ(this.stopCircle).first().offset().left;
			const positionLeftLine = jQ('.line').offset().left;
			const positionLeft = positionLeftLineConnection - positionLeftLine + stopCircleHeight/2;
			let activeIndex;

			jQ(this.stopCircle).each( (index, element) => {
				if(jQ(element).hasClass('stop-circle-active')) {
					activeIndex = index;
				}
			});

			const topWrapperImgDistanceY = activeIndex * singleUnitHeight + singleUnitHeightHalf;

			if(jQ(window).width() > 820) {
				
				jQ(this.topWrapperImg).css({
					"left": topWrapperImgDistanceX,
					"top": "auto"
				});

				jQ(this.lineElement).css({
					"width" : lineElemWidth,
					"top" : lineElemPosTop,
					"height": "2px",
					"left": positionLeft
					
				});

				jQ(this.bottomWrapper).css({
					"left" : singleUnitWidthHalf
				});			
			}
			else {
				
				jQ(this.lineElement).css({
					"height" : lineElemHeight,
					"top" : lineElemPosTopAlt,
					"width": "2px",
					"left": positionLeft
				});

				jQ(this.topWrapperImg).css({
					"left": "auto",
					"top": topWrapperImgDistanceY
				});	

				jQ(this.bottomWrapper).css({
					"left" : 0
				});			
			}
		}
	}
}

const positionLineConnect = new PositionElement('.line-wrapper', '.line-connection', '.stop', '.stop-circle', '.bottom-wrapper', '.top-wrapper svg', '.stop-circle-active');

export default positionLineConnect;
