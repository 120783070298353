const jQ = jQuery.noConflict();

class StickyHeader {
	constructor(mainHeader='.main-header', bannerSlider='.banner-slider') {
		this.mainHeader = mainHeader;
		this.bannerSlider = bannerSlider;
	}

	init() {
		this.setSticky();
	}

	setSticky() {
		const windowScroll = jQ(window).scrollTop();
		const headerOffsetTop = jQ(this.mainHeader).offset().top;
		const headerHeight = jQ(this.mainHeader).outerHeight();
		let lastScrollTop = 0;
	
		if(window.navigator.userAgent.indexOf('Edge') > -1 || !!window.MSInputMethodContext && !!document.documentMode ){

			if( jQ(this.bannerSlider).length > 0) {
				const bannerHeight = jQ(this.bannerSlider).outerHeight();
				if( windowScroll >= bannerHeight ) {

					if(!jQ(this.mainHeader).hasClass('is-sticky')){
						jQ(this.mainHeader).addClass('is-sticky');
						jQ('main').css({
							'margin-top': bannerHeight + headerHeight - 2
						});
						jQ('.banner-slider').css({
							'margin-top': headerHeight
						});
					}
				}			
				else {
					if(jQ(this.mainHeader).hasClass('is-sticky')) {
						jQ(this.mainHeader).removeClass('is-sticky');
						jQ('main').css({
							'margin-top': bannerHeight
						});
						jQ('.banner-slider').css({
							'margin-top': 0
						});
					}	
				}
			}
			else {
				if( windowScroll >= 0) {
					if(!jQ(this.mainHeader).hasClass('is-sticky')){
						jQ(this.mainHeader).addClass('is-sticky');
						jQ('main').css({
							'margin-top': jQ(this.mainHeader).outerHeight() - 2
						});
						jQ('.banner-slider').css({
							'margin-top': jQ(this.mainHeader).outerHeight()
						});
					}
				}			
				else {
					if(jQ(this.mainHeader).hasClass('is-sticky')) {
						jQ(this.mainHeader).removeClass('is-sticky');
						jQ('main').css({
							'margin-top': 0
						});
						jQ('.banner-slider').css({
							'margin-top': 0
						});
					}	
				}
			}
		}
		else {
			jQ(this.mainHeader).css({
				'position': 'sticky',
				'top': 0
			})
		}
	}
}

const setHeaderSticky = new StickyHeader('.main-header', '.banner-slider');

export default setHeaderSticky;