const jQ = jQuery.noConflict();

class OpenVideoWindow {
	constructor(selector='.row-video-placeholder', videoContainer='.video-container', section='.videos-texts') {
		this.selector = selector;
		this.videoContainer = videoContainer;
		this.section = section;
	}
	
	init() {
		jQ(this.selector).on('click', (e) => this.openVideoPopup(e));
		jQ(this.selector).parent().parent().find('.playpause').on('click', (e) => this.closeVideoPopup(e));
		jQ(this.selector).parent().parent().find('.fullscreen').on('click', (e) => this.toggleFullScreen(e));
		jQ(this.selector).parent().parent().find('.progress').on('click', (e) => this.setProgress(e));
	}

	openVideoPopup(e) {
		if(!!jQ('video')[0].canPlayType) {

			const videoContainer = jQ(e.currentTarget).parent().parent().find('.video-container');
			const video = jQ(e.currentTarget).parent().parent().find('.video-content');
			let videoControls = jQ(e.currentTarget).parent().parent().find('.video-controls');
			const progress = jQ(e.currentTarget).parent().parent().find('.progress');
			const progressBar = jQ(e.currentTarget).parent().parent().find('.progress-bar');
			const section = jQ(e.currentTarget).closest('section');

			video[0].controls = false;

			section.addClass('video-open');
			videoContainer.addClass('video-show');

			video[0].play();

   			progress.attr('max', video[0].duration);

			if(videoContainer.hasClass('video-fullscreen')) {
				videoContainer.removeClass('video-fullscreen');
			}
		
			video.on('timeupdate', () => {
				if (!progress.attr('max')) progress.attr('max', video[0].duration);
				progress.val(video[0].currentTime);
				let progressWidth =  Math.floor((video[0].currentTime / video[0].duration) * 100) + '%';
				progressBar.css('width', progressWidth);
			});
		}
		
	}

	toggleFullScreen(e) {
		e.stopPropagation();

		const videoContainer = jQ(e.currentTarget).closest('.video-container');
		const video = videoContainer.find('.video-content');
		const section = jQ(e.currentTarget).closest('section');

		if(!videoContainer.hasClass('video-fullscreen')) {
			videoContainer.addClass('video-fullscreen');
			section.height(video.outerHeight());
		}
		else {
			videoContainer.removeClass('video-fullscreen');
			section.height('auto');
		}
	}

	setProgress(e) {
		e.stopPropagation();
		const video = jQ(e.currentTarget).closest('.video-container').find('.video-content');
		const position = (e.pageX - jQ(e.currentTarget).offset().left)/jQ(e.currentTarget).outerWidth();
		video[0].currentTime = video[0].duration * position;
	}

	closeVideoPopup(e) {
		e.stopPropagation();

		const videoContainer = jQ(e.currentTarget).closest('.video-container');
		const video = jQ(e.currentTarget).closest('.video-container').find('.video-content');
		const progress = jQ(e.currentTarget).closest('.video-container').find('.progress');
		const section = jQ(e.currentTarget).closest('section');

		video[0].pause();
   		video[0].currentTime = 0;
   		progress.value = 0;

		if(videoContainer.hasClass('video-fullscreen')) {
			videoContainer.removeClass('video-fullscreen');
			section.height('auto');
		}

		section.removeClass('video-open');
		videoContainer.removeClass('video-show');

	}

	adjustVideoSectionOnResize() {
		if(jQ(this.videoContainer).hasClass('video-fullscreen')) {
			jQ(this.section).height(jQ('video').outerHeight());
		}
	}
}

const openVideo = new OpenVideoWindow('.row-video-placeholder', '.video-container', '.videos-texts');
const openVideoFull = new OpenVideoWindow('.video-overlay', '.video-container', '.video-fullwidth');

export default openVideo;

export {
	openVideoFull
}