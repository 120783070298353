const jQ = jQuery.noConflict();

class setActiveMenuItem {
	constructor(pageListItem='.page-list-item a') {
		this.pageListItem = pageListItem;
	}

	init() {
		this.setActiveItem();
	}

	setActiveItem() {
		const pageUrl = window.location.href;

		if(jQ(this.pageListItem).length) {
			jQ(this.pageListItem).each( (index, element) => {

				const itemLink = jQ(element).attr('href');
				const itemLinkName = itemLink.substr(0, itemLink.indexOf('.'));

				if(pageUrl.indexOf(itemLinkName) > -1) {
					jQ(element).addClass('active-link');
				}
			});
		}
	}
}

const setMenuItem = new setActiveMenuItem('.page-list-item a');

export default setMenuItem;